import "core-js/modules/es.array.push.js";
import AddPersonTab from '@/components/crm/addresses/addAddress/addPerson/AddPersonTab.vue';
import AddNonPersonTab from '@/components/crm/addresses/addAddress/addNonPerson/AddNonPersonTab.vue';
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson,
      navigationList: state => state.crmAddresses.navList,
      patientOptions: state => state.crmAddresses.patientOptions,
      validAddress: state => state.crmAddresses.validAddress,
      validPatient: state => state.crmAddresses.validPatient,
      validRelative: state => state.crmAddresses.validRelative,
      validEmployee: state => state.crmAddresses.validEmployee,
      validDoctor: state => state.crmAddresses.validDoctor,
      validGeneralInfo: state => state.crmAddresses.validGeneralInfo,
      validCompany: state => state.crmAddresses.validCompany,
      validSupplier: state => state.crmAddresses.validSupplier,
      person: state => state.crmAddresses.newPerson,
      nonPerson: state => state.crmAddresses.newNonPerson,
      addressTypes: state => state.crmAddresses.addressTypes
    })
  },
  components: {
    AddPersonTab,
    AddNonPersonTab
  },
  data: () => ({
    dialogTab: null,
    personErrorMessage: '',
    nonPersonErrorMessage: ''
  }),
  methods: {
    refMeTo(tab, ref) {
      const childComponent = this.$refs[tab];
      if (childComponent) {
        const childRef = childComponent.$refs[ref];
        if (childRef) {
          console.log(childRef);
          console.log(ref);
          childRef.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      }
    },
    updatePersonErrorMessage() {
      this.personErrorMessage = '';
      if (!this.validAddress || !(this.person.isConsumer || this.person.isAdultRepresentation || this.person.isPatient || this.person.isEmployee || this.person.isDoctor || this.person.isInterested)) {
        this.personErrorMessage += 'Adressinformationen unvollständig!\n';
      }
      if (this.person.isPatient && !this.validPatient) {
        this.personErrorMessage += 'Patienteninformationen unvollständig!\n';
      }
      if (this.person.isRelative && !this.validRelative) {
        this.personErrorMessage += 'Sachwalterinformationen unvollständig!\n';
      }
      if (this.person.isEmployee && !this.validEmployee) {
        this.personErrorMessage += 'Mitarbeiterinformationen unvollständig!\n';
      }
      if (this.person.isDoctor && !this.validDoctor) {
        this.personErrorMessage += 'Arztinformationen unvollständig!\n';
      }
    },
    updateNonPersonErrorMEssage() {
      this.nonPersonErrorMessage = '';
      if (!this.validGeneralInfo || !(this.nonPerson.isCompany || this.nonPerson.isAdultRepresentation || this.nonPerson.isResidence || this.nonPerson.isSupplier || this.nonPerson.isHealthInsuranceCompany) // TODO: check if isHealthInsuranceCompoany should be there. If yes --> add it to isValidNonPerson()
      ) {
        this.nonPersonErrorMessage += 'Allgemeine Informationen Unvollständig!';
      }
    },
    updateErrorMessage() {
      if (this.dialogTab == 0) {
        this.updatePersonErrorMessage();
      } else if (this.dialogTab == 1) {
        this.updateNonPersonErrorMEssage();
      }
    },
    isValidPerson() {
      //TODO: Maininsurancedata is not yet checked here
      return this.validAddress && (this.person.isPatient && this.validPatient || !this.person.isPatient) && (this.person.isRelative && this.validRelative || !this.person.isRelative) && (this.person.isEmployee && this.validEmployee || !this.person.isEmployee) && (this.person.isDoctor && this.validDoctor || !this.person.isDoctor) && (this.person.isConsumer || this.person.isAdultRepresentation || this.person.isPatient || this.person.isEmployee || this.person.isDoctor || this.person.isInterested);
    },
    isValidNonPerson() {
      return (this.nonPerson.isCompany || this.nonPerson.isAdultRepresentation || this.nonPerson.isResidence || this.nonPerson.isSupplier || this.nonPerson.isHealthInsuranceCompany) && this.validGeneralInfo && (this.nonPerson.isCompany && this.validCompany || !this.nonPerson.isCompany) && (this.nonPerson.isSupplier && this.validSupplier || !this.nonPerson.isSupplier);
    },
    isValid() {
      if (this.dialogTab == 0) {
        return this.isValidPerson();
      } else if (this.dialogTab == 1) {
        return this.isValidNonPerson();
      }
    },
    save() {
      if (this.dialogTab == 0) {
        //--> person
        this.savePerson();
      } else if (this.dialogTab == 1) {
        //--> non-person
        this.saveNonPerson();
      }
      //this.resetValuesToDefault()
    },
    savePerson() {
      if (this.person.isPatient) {
        //map healthInsurance company to its key
        this.person.patient.healthInsuranceCompany = {
          lfdnr: this.person.patient.healthInsuranceCompany.key.lfdnr,
          firma: this.person.patient.healthInsuranceCompany.key.firma
        };
        if (this.person.isRelative) {
          this.person.patient.mainInsuranceData.healthInsuranceCompany = {
            lfdnr: this.person.patient.mainInsuranceData.healthInsuranceCompany.key.lfdnr,
            firma: this.person.patient.mainInsuranceData.healthInsuranceCompany.key.firma
          };
        }
        this.person.patient.svnr = parseInt(this.person.patient.svnr);
        this.person.patient.familyDoctor = this.person.patient.familyDoctor.key;
        this.person.patient.familyDoctorName = this.person.patient.familyDoctor.familyDoctorName;
        this.person.patient.familyDoctorHvnr = this.person.patient.familyDoctor.hvnr;
      }
      if (this.person.isEmployee) {
        if (this.person.employee.healthInsuranceCompany !== null) {
          this.person.employee.healthInsuranceCompany = {
            lfdnr: this.person.employee.healthInsuranceCompany.key.lfdnr,
            firma: this.person.employee.healthInsuranceCompany.key.firma
          };
        }
        if (this.person.employee.warehouse !== null) {
          this.person.employee.warehouse = {
            firma: this.person.employee.warehouse.id.firma,
            code: this.person.employee.warehouse.id.code
          };
        }
      }
      if (this.person.isDoctor) {
        console.warn(this.person.doctor.subject);
        if (this.person.doctor.subject.id !== undefined) {
          this.person.doctor.subject = {
            firma: this.person.doctor.subject.id.firma,
            lfdnr: this.person.doctor.subject.id.lfdnr
          };
        }
      }
      this.$store.dispatch('saveNewPerson', this.person).then(result => {
        this.$router.push({
          name: 'crmAddress',
          params: {
            lfdnr: result.data.key.lfdnr
          }
        });
        this.resetValuesToDefault();
      });
    },
    saveNonPerson() {
      if (this.nonPerson.isCompany) {
        if (this.nonPerson.company.customerGroup !== null) {
          this.nonPerson.customerGroup = this.nonPerson.company.customerGroup;
        }
      }
      this.$store.dispatch('saveNewNonPerson', this.nonPerson).then(result => {
        this.$router.push({
          name: 'crmAddress',
          params: {
            lfdnr: result.data.key.lfdnr
          }
        });
        this.resetValuesToDefault();
      });
    },
    loadDefaults() {
      this.$store.dispatch('loadAddAddressOptions');
    },
    resetValuesToDefault() {
      this.$store.commit('resetNewPerson');
      this.$store.commit('resetNewNonPerson');
    },
    updatePersonNavList() {
      this.$store.commit('updatePersonNavList');
    },
    updateNonPersonNavList() {
      this.$store.commit('updateNonPersonNavList');
    }
  },
  mounted() {
    this.loadDefaults();
  }
};